import apexAssault from "@/inline-assets/apex-assault.svg";
import apexController from "@/inline-assets/apex-controller.svg";
import apexRecon from "@/inline-assets/apex-recon.svg";
import apexSkirmisher from "@/inline-assets/apex-skirmisher.svg";
import apexSupport from "@/inline-assets/apex-support.svg";

export const CLASS_ICONS = {
  assault: apexAssault,
  skirmisher: apexSkirmisher,
  support: apexSupport,
  recon: apexRecon,
  controller: apexController,
};
