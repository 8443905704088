import React from "react";
import { useTranslation } from "react-i18next";

import Warning from "@/inline-assets/Warning.svg";
import type { ToggleSettings } from "@/shared/BenchmarkOverlay.js";
import { Row } from "@/shared/CharacterSelectOverlay.style.jsx";
import type { VideoAdRefsProps } from "@/shared/OverlayContainerWithAd.jsx";
import OverlayContainerWithAd from "@/shared/OverlayContainerWithAd.jsx";

type CharacterInfo = {
  id: string;
  image: string;
  title: string | React.JSX.Element;
  stats: {
    valueStr: string;
    color?: string;
  }[];
};

type CharRowProps = {
  char: CharacterInfo;
  cols: Translation[];
};

type CharacterSelectOverlayProps = {
  game: string;
  overlayId: string;
  overlayIdAd: string;
  initialPosition?: string;
  cols: Translation[];
  selectedCharacter: CharacterInfo;
  suggestedCharacters: CharacterInfo[];
  refs: VideoAdRefsProps;
  toggleSettings?: ToggleSettings;
  emptyStateCopy: string;
};

const CharRow = ({ char, cols }: CharRowProps) => {
  const { t } = useTranslation();
  const uniqueCols = Object.entries(Object.fromEntries(cols));

  return (
    <Row>
      <img src={char?.image || null} />
      <p className="type-subtitle2 name">{char?.title || "-"}</p>
      {uniqueCols.map(([i18nKey, value], i) => (
        <div key={i18nKey}>
          <p
            className="type-subtitle2 name"
            style={{ color: char?.stats?.[i]?.color }}
          >
            {char?.stats?.[i]?.valueStr || "-"}
          </p>
          <p className="type-caption sub-stat">{t(i18nKey, value as string)}</p>
        </div>
      ))}
    </Row>
  );
};

function CharacterSelectOverlay({
  game,
  overlayId,
  overlayIdAd,
  initialPosition,
  cols,
  selectedCharacter,
  suggestedCharacters,
  refs,
  toggleSettings,
  emptyStateCopy,
}: CharacterSelectOverlayProps) {
  const { t } = useTranslation();

  return (
    <OverlayContainerWithAd
      overlayId={overlayId}
      overlayIdAd={overlayIdAd}
      initialPosition={initialPosition}
      game={game}
      refs={refs}
      toggleSettings={toggleSettings}
    >
      <div className="pad-1-3">
        <div className="type-mini color-shade1">
          {t("common:previous", "Previous")}
        </div>
        <CharRow char={selectedCharacter} cols={cols} />
        {suggestedCharacters?.length ? (
          <>
            <div className="type-mini color-shade1">
              {t("common:suggested", "Suggested")}
            </div>
            {suggestedCharacters.map((char) => (
              <CharRow key={char.id} char={char} cols={cols} />
            ))}
          </>
        ) : (
          <div className="flex align-center">
            <div className="pad-3">
              <Warning width="21" color="var(--shade1)" />
            </div>
            <p className="type-body2 color-shade1">{emptyStateCopy}</p>
          </div>
        )}
      </div>
    </OverlayContainerWithAd>
  );
}

export default CharacterSelectOverlay;
