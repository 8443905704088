import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css, styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import { kdaColorStyle } from "@/app/util.mjs";
import { OVERLAY_IDS } from "@/game-apex/constants.mjs";
import { CLASS_ICONS } from "@/game-apex/constants/constants-class-icons.mjs";
import { apexLegendSelectRefs } from "@/game-apex/refs.mjs";
import apexStatic from "@/game-apex/static.mjs";
import useApexLegends from "@/game-apex/use-apex-legends.mjs";
import useParams from "@/game-apex/utils/use-params.mjs";
import CharacterSelectOverlay from "@/shared/CharacterSelectOverlay.jsx";
import { getLocaleString } from "@/util/i18n-helper.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const cssInitialPosition = () => css`
  position: absolute;
  top: 0;
  left: 0;
`;

const Name = styled("div")`
  display: flex;
  align-items: center;
  svg {
    width: var(--sp-4);
    margin-right: var(--sp-1_5);
  }
`;

function ApexLegendSelectOverlay() {
  const route = useRoute();
  const { t } = useTranslation();
  const { searchParams } = route;
  const { season, mode: modeParam } = useParams(searchParams);
  const profileId = searchParams.get("profileId");
  const state = useSnapshot(readState);
  const isLegendSelectOverlayEnabled = // @ts-ignore
    state.settings.apex.overlays?.isLegendSelectOverlayEnabled;
  const profile = state.apex.profiles[profileId];
  const liveGame = state.apex.liveGame;
  const { legends: aggLegends } = useApexLegends({
    season,
    mode: modeParam,
    profileId,
  });

  const loggedInPlayerLegendId =
    state.settings.loggedInAccounts?.apex?.[profileId]?.hoveredChampionApexId ||
    profile?.hoveredChampionApexId;
  const loggedInPlayerLegend = aggLegends.find(
    (l) => l.championId === loggedInPlayerLegendId,
  );
  const legends = state.apex.meta.legends;

  const getLegendStats = useCallback(
    (legend) => {
      const Icon = CLASS_ICONS[legends[legend.championId]?.class];
      return {
        id: legend.championId,
        image: apexStatic.getLegendImage(legend.championId),
        title: (
          <Name>
            {Icon ? <Icon /> : null}
            {legends[legend.championId]?.name}
          </Name>
        ),
        stats: [
          {
            valueStr: getLocaleString(legend?.damagePerMatch, {
              maximumFractionDigits: 1,
            }),
          },
          {
            valueStr: getLocaleString(legend?.killsPerMatch, {
              maximumFractionDigits: 1,
            }),
            color: kdaColorStyle(legend?.killsPerMatch),
          },
          {
            valueStr: getLocaleString(legend?.placements_win, {
              maximumFractionDigits: 1,
            }),
          },
        ],
      };
    },
    [legends],
  );

  const legend = useMemo(() => {
    if (loggedInPlayerLegend) return getLegendStats(loggedInPlayerLegend);
  }, [getLegendStats, loggedInPlayerLegend]);

  const suggestedLegends = useMemo(() => {
    return aggLegends.slice(0, 3).map(getLegendStats);
  }, [aggLegends, getLegendStats]);

  if (IS_APP && (!profile || !isLegendSelectOverlayEnabled || !liveGame))
    return null;

  return (
    <CharacterSelectOverlay
      game={"apex"}
      overlayId={OVERLAY_IDS.apexLegendSelect}
      overlayIdAd={OVERLAY_IDS.apexLegendSelectAd}
      initialPosition={cssInitialPosition()}
      refs={apexLegendSelectRefs}
      cols={[
        ["common:dmgPerMatch", "Dmg/Match"],
        ["common:killsPerMatch", "Kills/Match"],
        ["common:wins", "Wins"],
      ]}
      selectedCharacter={legend}
      suggestedCharacters={suggestedLegends}
      emptyStateCopy={t(
        "apex:legendSelect:playMatches",
        "Play unranked or ranked matches to generate legend suggestions.",
      )}
    />
  );
}

export function meta() {
  return {
    title: [null, "Apex - Legend Select Overlay"],
    description: [null, "Apex Legend Select Overlay"],
  };
}

export default ApexLegendSelectOverlay;
