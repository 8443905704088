import { styled } from "goober";

export const Row = styled("div")<{ $imgSize?: string; src?: string }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: var(--sp-1_5) 0;
  position: relative;
  overflow: hidden;
  transition: background-color var(--transition);

  &:first-of-type {
    padding-top: 0;
  }

  .name {
    flex: 0.6;
    display: flex;
    justify-content: start;
  }

  img {
    width: ${(props) => (props.$imgSize ? props.$imgSize : "var(--sp-10)")};
    height: auto;
    border-radius: var(--br);
    background-image: url(${(props) => props.src});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    flex-shrink: 0;
  }

  .sub-stat {
    color: var(--shade1);
  }
`;
